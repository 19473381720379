import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery5 from '../PhotoGallery/PhotoG5';
import SponsorLogo from '../../Assets/logos/UNICEF_Logo.png'; // Replace with the correct logo path

const OutofschoolPost = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Operation Come to School Program</h1>
        <p className="post-description">
          ROAD, with funding from UNICEF, is implementing the “Operation Come to School” program. The program recognizes the specific needs and unique contextual factors that shape access to quality educational opportunities for marginalized children in Garissa County. This initiative aims to bring back 10,125 out-of-school children.
        </p>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            <img src={SponsorLogo} alt="UNICEF Logo" className="sponsor-logo" />
            <h2 className="sponsor-name"></h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery5 />
      </div>
    </div>
  );
};

export default OutofschoolPost;
