import React from 'react';
import './PartnersSection.css'; 
import Slider from 'react-slick';

// Import your partner logos
import partnerLogo1 from '../../Assets/logos/FAO_logo.svg_.png';
import partnerLogo2 from '../../Assets/logos/DAI-1.jpg';
import partnerLogo3 from '../../Assets/logos/ICRC.png';
import partnerLogo4 from '../../Assets/logos/IHH-Turkish-NGO.png-1.jpg';
import partnerLogo5 from '../../Assets/logos/smith-international.png';
import partnerLogo6 from '../../Assets/logos/UNICEF_Logo.png';
import partnerLogo7 from '../../Assets/logos/African-Women-Millennium-Initiative-AWOMI.jpg';
import partnerLogo8 from '../../Assets/logos/DANIDA-Logo.png';
import partnerLogo9 from '../../Assets/logos/Danish-logo.jpg';
import partnerLogo10 from '../../Assets/logos/SIDALogo.png';
import partnerLogo11 from '../../Assets/logos/ACDIVOCALogo.jpg';
import partnerLogo12 from '../../Assets/logos/GOK.png';
import partnerLogo13 from '../../Assets/logos/IOM UN Migration.png';
import partnerLogo14 from '../../Assets/logos/CRSlOGO.jpg';

const PartnersSection = () => {
    // Custom arrow components
    const PrevArrow = (props) => (
      <div {...props} className="slick-arrow slick-prev">
      </div>
    );
  
    const NextArrow = (props) => (
      <div {...props} className="slick-arrow slick-next">
      </div>
    );
  // Configuration for the slider
  const sliderSettings = {
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Adjust the number of logos displayed per slide as needed
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  // Array of partner logos
  const partnerLogos = [
    partnerLogo1,
    partnerLogo2,
    partnerLogo3,
    partnerLogo4,
    partnerLogo5,
    partnerLogo6,
    partnerLogo7,
    partnerLogo8,
    partnerLogo9,
    partnerLogo10,
    partnerLogo11,
    partnerLogo12,
    partnerLogo13,
    partnerLogo14,
  ];

  return (
    <div className="partners-section">
      <h1>Organizations we work closely with</h1>
      <p>We have partnered with top leading organizations in the world in delivering our services.</p>

      <div className="partner-logos">
        <Slider {...sliderSettings}>
          {partnerLogos.map((logo, index) => (
            <div key={index} className="partner-logo">
              <img src={logo} alt={`Partner Logo ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PartnersSection;
