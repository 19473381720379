import React from 'react';
import './GarissaCountyActionPlan.css'; // Reuse the same CSS
import PhotoGallery6 from '../PhotoGallery/PhotoG6';

const CommunityResiliencePost = () => {
  return (
    <div className="garissa-county-action-plan-container"> {/* Use the same container class */}
      {/* Project Description Section */}
      <div className="project-description-card"> {/* Reuse the same card class */}
        <h1 className="post-title">Promoting Community Resilience to Conflicts and Violent Extremism (2023-2024)</h1>
        <p className="post-description">
          This initiative focuses on fostering community resilience to conflicts and violent extremism in Garissa County. Implemented from 2023 to 2024, the project aims to build stronger communities capable of withstanding conflicts' impact and resisting the influence of violent extremism. It strategically addresses the root causes of conflicts, promoting sustainable peace and development in the region.
        </p>
        <br />
        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <h2 className="sponsor-name">[Sponsor Name]</h2> {/* Add sponsor name if available */}
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card"> {/* Reuse the same card class */}
        <PhotoGallery6 />
      </div>
    </div>
  );
};

export default CommunityResiliencePost;
