import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery2 from '../PhotoGallery/PhotoG2';
import SponsorLogo from '../../Assets/logos/IHH-Turkish-NGO.png-1.jpg'; // Replace with the correct logo path

const LivestockMarketPost = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Livestock Market Systems – Strengthening Communities' Capacities for Resilience and Growth (LMS)
          <br /> Background to LMS Program:
        </h1>
        <p className="post-description">
          Mercy Corps, a leading global organization powered by the belief that a better world is possible, together with ACDI/VOCA, is implementing a five-year USAID-funded Feed the Future Livestock Market Systems (LMS) Activity for northern Kenya. The project’s premise is that through collective action and strengthened formal and informal institutions, systems, governance, and human capital, people’s resilience to shocks and stresses is strengthened. Thus, the goal of the program is to reduce the prevalence and depth of chronic poverty, hunger, and under-nutrition.
        </p>

        <p className="post-description">
          Rights Organization for Advocacy and Development (ROAD) International has been subcontracted to implement the LMS program under Associate Award 2. ROAD will implement the LMS program by forming and building the capacity of two Ward Planning Committees (WPCs) in Balambala and Waberi while supporting advocacy and influencing of three other established WPCs.
        </p>

        <div className="objectives">
          <h2>1. Strengthen Institutions, Systems, and Governance:</h2>
          <ul>
            <li>Supporting 2 wards (peri-urban and rural) in establishing and building the capacity of Ward Development Planning.</li>
            <li>Strengthening and sustaining rangeland and water management.</li>
            <li>Enhancing drought risk management.</li>
            <li>Strengthening conflict management.</li>
          </ul>

          <h2>2. Improve Human Capital:</h2>
          <ul>
            <li>Improved and sustained health, nutrition, and hygiene practices, including COVID-19 prevention activities.</li>
            <li>The program targets Waberi and Balambala wards, guided by the Ward Development Planning Process Toolkit developed by Mercy Corps.</li>
          </ul>
        </div>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            <img src={SponsorLogo} alt="Sponsor Logo" className="sponsor-logo" />
            <h2 className="sponsor-name">& ACDI/VOCA</h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery2 />
      </div>
    </div>
  );
};

export default LivestockMarketPost;
