import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery4 from '../PhotoGallery/PhotoG4';
// import SponsorLogo from '../../Assets/logos/ihh-humanitarian.png'; 

const LiveliHoodPost = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h3 className="post-title">Livelihood Project</h3>
        <p className="post-description">
          ROAD, with support from IHH Humanitarian Relief Foundation, provided urgent food and medical aid to the refugee population of Daadab and the host community.
        </p>

        <div className="objectives">
          <h2>Purpose for the project:</h2>
          <p className="post-description">
            The goal of the project was to reduce the loss of lives and livelihoods through appropriate and timely interventions in response to the prevailing drought condition. This was done by providing food, shelter, and emergency medical support, aimed at reducing malnutrition in the drought-affected population.
          </p>
          <br />
          <h2>Benefits and Beneficiaries</h2>
          <p className="post-description">
            The project aimed to support 3,000 households, with each household consisting of around six members. These households were among the most vulnerable, with the highest malnutrition rates, and were identified through an assessment.
          </p>
        </div>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            {/* <img src={SponsorLogo} alt="Sponsor Logo" className="sponsor-logo" /> */}
            <h2 className="sponsor-name">IHH Humanitarian Relief Foundation</h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery4 />
      </div>
    </div>
  );
};

export default LiveliHoodPost;
