import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery-CVE.css'; // Reuse the same CSS

import Image1 from '../../Assets/images/newImages/CSO Capacity building.jpeg';
import Image2 from '../../Assets/images/newImages/Derisawanag.jpeg';
import Image3 from '../../Assets/images/newImages/Deriswanag peace building.jpeg';
import Image4 from '../../Assets/images/newImages/Deriswanag peace building1.jpeg';
import Image5 from '../../Assets/images/newImages/Deriswanag.jpeg';
import Image6 from '../../Assets/images/newImages/JOINT MONITORING dasheeg farm.jpeg';
import Image7 from '../../Assets/images/newImages/PREG JOINT MONITORING 3.jpeg';
import Image8 from '../../Assets/images/newImages/PREG JOINT MONITORING 4.jpeg';
import Image9 from '../../Assets/images/newImages/PREG JOINT MONITORING 5.jpeg';
import Image10 from '../../Assets/images/newImages/PREG JOINT MONITORING1.jpeg';
import Image11 from '../../Assets/images/newImages/PREG JOINT MONITORING2.jpeg';
import Image12 from '../../Assets/images/newImages/PREG MONITORING.jpeg';
import Image13 from '../../Assets/images/newImages/PREG Partner and county government Co-creation workshop.jpeg';
import Image14 from '../../Assets/images/newImages/RLA.jpeg';
import Image15 from '../../Assets/images/newImages/RLA1.jpeg';
import Image16 from '../../Assets/images/newImages/RLA2.jpeg';
import Image17 from '../../Assets/images/newImages/RLAjointMonitoringField.jpeg';
import Image18 from '../../Assets/images/newImages/SIDA.jpeg';
import Image19 from '../../Assets/images/newImages/SIDA1.jpeg';
import Image20 from '../../Assets/images/newImages/SIDA2.jpeg';

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8, Image9, Image10, Image11, Image12, Image13, Image14, Image15, Image16, Image17, Image18, Image19, Image20];

const PhotoGallery6 = () => {
  return (
    <div className="photo-gallery-container">
      <div className="photo-gallery">
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={4000} transitionTime={1000}>
          {images.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Image ${index + 1}`} className="gallery-image"/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PhotoGallery6;
