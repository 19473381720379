import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery-CVE.css';

import Image1 from '../../Assets/images/IOMProject1/IMG-20170718-WA0007.jpg';
import Image2 from '../../Assets/images/IOMProject1/IMG-20170716-WA0024.jpg';
import Image3 from '../../Assets/images/IOMProject1/IMG-20170718-WA0002.jpg';
import Image4 from '../../Assets/images/IOMProject1/IMG-20170718-WA0007.jpg';
import Image5 from '../../Assets/images/IOMProject1/IMG-20170718-WA0010.jpg';

const images = [Image1, Image2, Image3, Image4, Image5];

const PhotoGallery3 = () => {
  return (
    <div className="photo-gallery-container">
      <div className="photo-gallery">
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={4000} transitionTime={1000}>
          {images.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Image ${index + 1}`} className="gallery-image"/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PhotoGallery3;
