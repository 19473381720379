import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery from '../PhotoGallery/PhotoG1';
import SponsorLogo from '../../Assets/logos/smith-international.png'; 

const GarissaCountyActionPlan = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Garissa County Action Plan (CVE)</h1>
        <p className="post-description">
          This action plan has gone further to sort information on what would be the central area of Action for the County of Garissa. To further ensure that the plan is result-based, the implementation schedule of the Action Plan is organized around five major strategic objectives. The objectives have been discussed and presented within the Objectives and Key Results (OKR) framework.
        </p>

        <div className="objectives">
          <h2>Objectives:</h2>
          <ul>
            <li>Providing at-risk individuals with vocational and life skills training</li>
            <li>Establishment and promotion of structures for political socialization of the youth</li>
            <li>Providing mentoring and religious guidance for at-risk individuals</li>
            <li>Establishment of CVE forums to enhance collaboration between key state and non-state actors</li>
            <li>Development and implementation of a framework for integrating the county</li>
          </ul>
        </div>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            <img src={SponsorLogo} alt="Sponsor Logo" className="sponsor-logo" />
            <h2 className="sponsor-name"></h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery />
      </div>
    </div>
  );
};

export default GarissaCountyActionPlan;
