import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery6 from '../PhotoGallery/PhotoG6';


const ResilienceAdaptationPost = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Resilience Learning and Adaptation Project in Garissa County</h1>
        <p className="post-description">
          The Resilience Learning and Adaptation Project, conducted in Garissa County, operates from 2022 to 2024. Funded by USAID, its primary objective is to coordinate efforts with the County Government and all project partners. The project aims to prevent duplication of initiatives, enhance synergy, and strengthen partnerships to optimize resources for sustainable development in the region. By fostering collaboration, the project contributes to a harmonized approach, avoiding redundancies and maximizing the impact of interventions.
        </p>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            {/* <img src={SponsorLogo} alt="USAID Logo" className="sponsor-logo" /> */}
            <h2 className="sponsor-name">USAID</h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery6 />
      </div>
    </div>
  );
};

export default ResilienceAdaptationPost;
