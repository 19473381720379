import React from 'react';
import './GarissaCountyActionPlan.css';
// Import the sponsor logo if applicable
// import SponsorLogo from '../../Assets/logos/sponsor-logo.png'; // Replace with the correct logo path if needed

const WomenEmpowerment = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Women Empowerment</h1>
        <p className="post-description">
          In a world where women's empowerment is key to progress, ROAD International is dedicated to showcasing impactful projects. We believe that empowered women are change-makers, shaping communities and societies. Together, we can create a world where women are celebrated for their remarkable contributions. Join us in this transformative journey for women's empowerment.
        </p>

        {/* Sponsor Section */}
        {/* Uncomment if a sponsor is applicable */}
        {/* <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            <img src={SponsorLogo} alt="Sponsor Logo" className="sponsor-logo" />
            <h2 className="sponsor-name">Sponsor Name</h2>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default WomenEmpowerment;
