import React from 'react';
import { motion } from 'framer-motion';
import './ProjectSummary.css';

const projects = [
  { project: "Women Empowerment", location: "Garissa", donor: "AWOM", period: "2009-2011", status: "Completed" },
  { project: "Capacity development in strengthening National Government M&E systems", location: "Somaliland, Puntland", donor: "UN-FAO", period: "Jan 2012-Dec 2012", status: "Completed" },
  { project: "The	training of 30 trainers on leather production and development techniques along the livestock value chain and pilot test value added by –and core.", location: "Hargeisa, Somaliland", donor: "UN-FAO", period: "April 2013-July 2013", status: "Completed" },
  { project: "Time Critical Emergency Animal Health Treatment Project for	the protection of livestock disease for the poor pastoralist and agro-pastoralist’s households in Northern and central regions of Somalia.", location: "Northern and Central Somalia", donor: "UN-FAO", period: "2012-2015", status: "Completed" },
  { project: "Support to Pastoralist Communities on Livelihood Risk Reduction", location: "Northern Somalia", donor: "SOWELPA", period: "2012-2014", status: "Completed" },
  { project: "Qurban Project", location: "Dadaab", donor: "IHH", period: "2013", status: "Completed" },
  { project: "Aqiqa Project", location: "Dadaab", donor: "IHH", period: " 2014 ", status: "Completed" },
  { project: "Ramadhan Iftar Programme", location: "Dadaab", donor: "IHH", period: "2015", status: "Completed" },
  { project: "Urgent Food & Medical aid Project", location: "Garissa, Dadaab & Bordering Somalia towns", donor: "IHH", period: "2013-2015", status: "Completed" },
  { project: "Water Trucking", location: "Dadaab, Garissa", donor: "IHH", period: "2014", status: "Completed" },
  { project: "Leather project- Training of 90 beneficiaries	on leather value chain addition.", location: "Elwak- Jubaland Somalia", donor: "ICRC", period: "2Oct 2014-Dec 2014", status: "Completed" },
  { project: "Milk	value	addition project", location: "Hargeisa", donor: "UN-FAO", period: "2March 2015- Dec 2015", status: "Completed" },
  { project: "Peace	and	Capacity Building", location: "Gedo- Jubaland, Somalia", donor: "Internal Funds", period: "Feb 2016 – July", status: "Completed" },
  { project: "Bringing 10,125 Out of   School Children [OOSC] back to School, increasing their retention and learning outcomes.", location: "Garissa county North Kenya", donor: "UNICEF", period: "April 2017- December 2018", status: "Completed" },
  { project: "Development of County action plan on CVE.", location: "Garissa county, North Kenya", donor: "DAI/Niwetu", period: "April 2018 to November 2018", status: "Completed" },
  { project: "Enhancing community Security Cooperation on CVE.", location: "Garissa county, North Kenya", donor: "DAI/Niwetu", period: "March 2019 - March 2020", status: "Completed" },
  { project: "Strengthening communities’ capacities for Resilience and Growth, including Strengthening Institutions, Systems and Governance.", location: "Garissa County, Kenya.", donor: "USAID/Mercy Corps", period: "Feb 2021 - June 2022", status: "Completed" },
  { project: "Strengthening Joint Work Planning at the county level.", location: "Garissa County, Kenya.", donor: "Resilience learning Activity", period: "March 2022-February 2023", status: "Completed" },
  { project: "Strengthening communities’ capacities for Resilience and Growth, including Strengthening Institutions, Systems and Governance- LMS extension.", location: "Garissa County, Kenya.", donor: "USAID/Mercy Corps", period: "November 2022- March 2023", status: "Ongoing" },
  // ...other projects
];

const ProjectSummary = () => {
  return (
    <div className="project-summary-container">
      <h1 className="table-title">Donor Funds Received and Projects Implemented</h1>
      
      <motion.table 
        className="project-table"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <thead>
          <tr>
            <th>Project</th>
            <th>Location</th>
            <th>Donor</th>
            <th>Period</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <motion.tr 
              key={index} 
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <td>{project.project}</td>
              <td>{project.location}</td>
              <td>{project.donor}</td>
              <td>{project.period}</td>
              <td>{project.status}</td>
            </motion.tr>
          ))}
        </tbody>
      </motion.table>
    </div>
  );
};

export default ProjectSummary;
