import React from 'react';
import './GarissaCountyActionPlan.css'; // Reusing the Garissa County CSS
import PhotoGallery3 from '../PhotoGallery/PhotoG3';
import SponsorLogo from '../../Assets/logos/smith-international.png'; // Replace with the actual sponsor logo

const IomPost1 = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">IOM in Bakol Elbadre District</h1>
        <p className="post-description">
          Renovation of borehole and water tracking in the district, helping the local community access safe and reliable water sources.
        </p>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            {/* <img src={SponsorLogo} alt="Sponsor Logo" className="sponsor-logo" /> */}
            {/* <h2 className="sponsor-name">Adam Smith International</h2> */}
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery3 />
      </div>
    </div>
  );
};

export default IomPost1;
