import React from 'react';
import './GarissaCountyActionPlan.css';
import PhotoGallery6 from '../PhotoGallery/PhotoG6';


const SIRRProjectPost = () => {
  return (
    <div className="garissa-county-action-plan-container">
      {/* Project Description Section */}
      <div className="project-description-card">
        <h1 className="post-title">Supporting Initiatives in Rehabilitation and Reintegration (SIRR) Project (Ongoing till 2026)</h1>
        <p className="post-description">
          Funded by GCERF, this project supports initiatives focused on rehabilitating and reintegrating individuals affected by violent extremism in Garissa County. With ongoing implementation until 2026, the project facilitates comprehensive rehabilitation efforts, contributing to peace and stability in the region.
        </p>

        {/* Sponsor Section */}
        <div className="sponsor-section">
          <p>Sponsored by:</p>
          <div className="sponsor-info">
            {/* <img src={SponsorLogo} alt="GCERF Logo" className="sponsor-logo" /> */}
            <h2 className="sponsor-name">GCERF</h2>
          </div>
        </div>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery-card">
        <PhotoGallery6 />
      </div>
    </div>
  );
};

export default SIRRProjectPost;
