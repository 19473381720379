import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './PhotoGallery-CVE.css';

import Image1 from '../../Assets/images/IHH_Turkey-min.jpg';
import Image2 from '../../Assets/images/LivelihoodProject1/IHH_Turkey_1-min.jpg';
import Image3 from '../../Assets/images/LivelihoodProject1/IHH_Turkey_2-min.jpg';
import Image4 from '../../Assets/images/LivelihoodProject1/IHH_Turkey_3-min.jpg';
import Image5 from '../../Assets/images/LivelihoodProject1/IHH_Turkey_4-min.jpg';

const images = [Image1, Image2, Image3, Image4, Image5];

const PhotoGallery4 = () => {
  return (
    <div className="photo-gallery-container">
      <div className="photo-gallery">
        <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false} interval={4000} transitionTime={1000}>
          {images.map((image, index) => (
            <div key={index} className="carousel-slide">
              <img src={image} alt={`Image ${index + 1}`} className="gallery-image"/>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default PhotoGallery4;
