import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { FaFacebook, FaTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const [submenuType, setSubmenuType] = useState(null);

  const toggleSubmenu = (type) => {
    if (submenuType === type) {
      setShowSubmenu(!showSubmenu);
    } else {
      setShowSubmenu(true);
      setSubmenuType(type);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-section pages-section">
        <h4>Pages</h4>
        <ul>
          <li><a href="/" style={{ textDecoration: 'none', color: '#fff' }}>Home</a></li>
          <li><a href="/about-us" style={{ textDecoration: 'none', color: '#fff' }}>About Us</a></li>
          <li><a href="/thematic-areas" style={{ textDecoration: 'none', color: '#fff' }}>Thematic Areas</a></li>
          <li className="submenu-trigger" onClick={() => toggleSubmenu("projects")}>
            Projects {showSubmenu && submenuType === "work-with-us" ? <FaMinus className="minus-icon" /> : <FaPlus className="plus-icon" />}
          </li>
          {showSubmenu && submenuType === "projects" && (
            <ul className="sub-menu">
              <li><a href="/projects/ongoing-completed-projects" style={{ textDecoration: 'none', color: '#fff' }}>Ongoing & Completed Projects</a></li>
              <li><a href="/projects/donor-funds-&-projects-implemented" style={{ textDecoration: 'none', color: '#fff' }}>Donor Funds & Projects Implemented</a></li>
            </ul>
          )}
          <li className="submenu-trigger" onClick={() => toggleSubmenu("work-with-us")}>
            Work with Us {showSubmenu && submenuType === "work-with-us" ? <FaMinus className="minus-icon" /> : <FaPlus className="plus-icon" />}
          </li>
          {showSubmenu && submenuType === "work-with-us" && (
            <ul className="sub-menu">
              <li><a href="/work-with-us/expression-of-interest" style={{ textDecoration: 'none', color: '#fff' }}>Expression of Interest</a></li>
              <li><a href="/work-with-us/procurement" style={{ textDecoration: 'none', color: '#fff' }}>Tenders</a></li>
            </ul>
          )}
        </ul>
      </div>

      <div className="footer-section social-media-section">
        <h4>Social Media</h4>
        <div className="socialIcons1">
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaFacebook />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
        </div>
      </div>

      <div className="footer-section contact-us-section">
        <h4>Contact Us</h4>
        <ul>
          <li>Tel no: 020 7855149</li>
          <li>info@road-international.org</li> <br />
          <li>School Lane, Westlands, Nairobi, Kenya.</li> <br />
          <li>Sinay, State House Road, Kisimayo, Jubaland State, Somalia</li>
          <li>Gantalaha area, Hargeisa, Somaliland</li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
